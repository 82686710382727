import { put } from "redux-saga/effects";
import {
  API_MAGAZINE,
  API_MAGAZINE_CATEGORY,
  API_MAGAZINE_ORDER,
} from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";
import { takeLatest, takeEvery } from "redux-saga/effects";

export function* watchGetMagazineSaga() {
  yield takeLatest(actionType.GET_MAGAZINE_METHOD, getMagazineSaga);
}

export function* watchGetMagazineOrderSaga() {
  yield takeLatest(actionType.GET_MAGAZINE_ORDER_METHOD, getMagazineOrderSaga);
}

export function* watchGetMagazineCategoriesSaga() {
  yield takeLatest(
    actionType.GET_MAGAZINE_CATEGORY_METHOD,
    getMagazineCategorySaga
  );
}

export function* watchPostMagazineSaga() {
  yield takeEvery(actionType.POST_MAGAZINE_METHOD, createMagazineSaga);
}

export function* watchUpdateMagazineSaga() {
  yield takeEvery(actionType.UPDATE_MAGAZINE_METHOD, updateMagazineSaga);
}

export function* watchDeleteMagazineSaga() {
  yield takeEvery(actionType.DEL_MAGAZINE_METHOD, deleteMagazineSaga);
}

function* getMagazineCategorySaga(action) {
  try {
    let url = {
      type: API_MAGAZINE_CATEGORY,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_MAGAZINE_CATEGORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({
      type: actionType.GET_MAGAZINE_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

function* getMagazineSaga(action) {
  try {
    let url = {
      type: API_MAGAZINE,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_MAGAZINE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_MAGAZINE_FAILURE, payload: error });
  }
}

function* getMagazineOrderSaga(action) {
  try {
    let url = {
      type: API_MAGAZINE_ORDER,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_MAGAZINE_ORDER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_MAGAZINE_ORDER_FAILURE, payload: error });
  }
}

function* createMagazineSaga(action) {
  try {
    let url = {
      type: API_MAGAZINE,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.POST_MAGAZINE_SUCCESS, payload: res });
    yield put({ type: actionType.POST_MAGAZINE_CLEAR, payload: res });
  } catch (error) {
    yield put({ type: actionType.POST_MAGAZINE_FAILURE, payload: error });
  }
}

function* updateMagazineSaga(action) {
  try {
    let url = {
      type: API_MAGAZINE,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.UPDATE_MAGAZINE_SUCCESS, payload: res });
    yield put({ type: actionType.UPDATE_MAGAZINE_CLEAR, payload: res });
  } catch (error) {
    yield put({ type: actionType.UPDATE_MAGAZINE_FAILURE, payload: error });
  }
}

function* deleteMagazineSaga(action) {
  try {
    let url = {
      type: API_MAGAZINE,
      payload: action.payload,
      apiType: "DELETE",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.DEL_MAGAZINE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.DEL_MAGAZINE_FAILURE, payload: error });
  }
}
