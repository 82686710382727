import { actionType } from "appRedux/actionType";

export function getMagazineList(payload) {
  return {
    type: actionType.GET_MAGAZINE_METHOD,
    payload: payload,
  };
}

export function getMagazineCategoryList(payload) {
  return {
    type: actionType.GET_MAGAZINE_CATEGORY_METHOD,
    payload: payload,
  };
}

export function getMagazineOrderList(payload) {
  return {
    type: actionType.GET_MAGAZINE_ORDER_METHOD,
    payload: payload,
  };
}

export function saveMagazine(payload) {
  return {
    type: actionType.POST_MAGAZINE_METHOD,
    payload: payload,
  };
}

export function deleteMagazine(payload) {
  return {
    type: actionType.DEL_MAGAZINE_METHOD,
    payload: payload,
  };
}

export function updateMagazine(payload) {
  return {
    type: actionType.UPDATE_MAGAZINE_METHOD,
    payload: payload,
  };
}
