import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import { renderMatches } from "react-router-dom";
import colors from "utils/colors";

const cellStyle = {
  fontFamily: "Medium65",
  fontSize: "1.1rem",
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    headCells,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    listCheckBox,
    action,
    allRowSelected,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: colors.primary, color: colors.white }}>
        {listCheckBox && (
          <TableCell padding="checkbox" sx={cellStyle}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={allRowSelected}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            sx={cellStyle}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            colSpan={headCell.colSpan}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: colors.white }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {action && <TableCell sx={cellStyle}>Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
}

const DataTable = ({
  headCells,
  listCheckBox,
  rows,
  render,
  isCustomRow,
  customRow,
  onSelectAllCheckBox,
  allRowSelected,
  action,
}) => {
  return (
    <TableContainer>
      <Table>
        <EnhancedTableHead
          headCells={headCells}
          listCheckBox={listCheckBox}
          action={action}
          onSelectAllClick={onSelectAllCheckBox}
          allRowSelected={allRowSelected}
        />
        <TableBody>
          {rows.map((row, idx) => {
            return isCustomRow ? (
              <>{customRow(row)}</>
            ) : (
              <TableRow key={`idx-table-${idx}`}>{render(row)}</TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
