import React from "react";
import { Box, IconButton, TableCell } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import SButton from "common/material/panel/SButton";
import { DeleteIcon } from "common/icons";

const cellStyle = {
  fontFamily: "Roman55",
  fontSize: ".95rem",
};

const cellDisableStyle = {
  fontFamily: "Roman55",
  fontSize: ".95rem",
  opacity: "50%",
};

const RenderCellsUi = ({ row, el, editMagazine }) => {
  if (el === "title") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.magazine.title}
      </TableCell>
    );
  } else if (el === "order_id") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.order_id}
      </TableCell>
    );
  } else if (el === "cover") {
    return (
      <TableCell align="left" sx={cellStyle}>
        <img
          src={row.magazine.cover}
          alt={row.magazine.title}
          width={100}
          height={100}
        />
      </TableCell>
    );
  } else if (el === "bought_by") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.user.name}
      </TableCell>
    );
  } else if (el === "price") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.price}
      </TableCell>
    );
  } else if (el === "platform_fee_percent") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.platform_fee_percent}
      </TableCell>
    );
  } else if (el === "platform_fee_amount") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.platform_fee_amount}
      </TableCell>
    );
  } else if (el === "status") {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.status}
      </TableCell>
    );
  } else if (el === "action") {
    return (
      <TableCell>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#F8F8F8",
            p: 1,
            maxWidth: "200px",
          }}
        >
          {/* <Switch sx={{ mr: 1 }} {...label} checked={row.is_active} size="small" onClick={(e)=>handleActiveSwitch(e, row.id, !row.is_active)} /> */}
          <SButton
            mySx={{ borderRadius: "25px", mr: 1, width: "100px" }}
            variant="contained"
            size="small"
            onClick={(e) => editMagazine(e, row)}
          >
            Edit
          </SButton>
          <IconButton
          // onClick={(e) => handleDelete(e, row.id)}
          >
            <DeleteIcon size="small" />
          </IconButton>
        </Box>
      </TableCell>
    );
  } else {
    return (
      <TableCell align="left" sx={cellStyle}>
        {row.id}
      </TableCell>
    );
  }
};

export default RenderCellsUi;
