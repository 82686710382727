import { Box, Paper } from "@mui/material";
import DataTable from "common/DataTable";
import React, { useState } from "react";
import RenderCellsUi from "./RenderCellUI";

const MagazineOrderList = ({ rows }) => {
  const [column, setColumn] = useState([
    {
      id: "order_id",
      numeric: false,
      disablePadding: false,
      label: "Order ID",
    },
    { id: "cover", numeric: false, disablePadding: false, label: "Cover" },
    { id: "title", numeric: false, disablePadding: false, label: "Title" },
    {
      id: "bought_by",
      numeric: false,
      disablePadding: false,
      label: "Bought By",
    },
    { id: "price", numeric: false, disablePadding: false, label: "Price" },
    {
      id: "platform_fee_percent",
      numeric: false,
      disablePadding: false,
      label: "Platform Fee Percent",
    },
    {
      id: "platform_fee_amount",
      numeric: false,
      disablePadding: false,
      label: "Platform Fee Amount",
    },
    { id: "status", numeric: false, disablePadding: false, label: "Status" },
  ]);

  const showData = column.map((el) => el.id);

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        borderRadius: "15px",
        boxShadow: "0px 4px 6px rgba(0,97,85,.15)",
      }}
    >
      <DataTable
        rows={rows}
        headCells={column}
        // listCheckBox={true}
        render={(row) => (
          <>
            {/* <TableCell padding="checkbox">
              <Checkbox
                // onClick={(event) => handleClick(event, row.id)}
                checked={true}
                inputProps={{ "aria-labelledby": "xyz" }}
              />
            </TableCell> */}
            {showData.map((el, index) => (
              <RenderCellsUi
                key={`magazine-order-list-${index}`}
                row={row}
                el={el}
                // editMagazine={editMagazine}
              />
            ))}
          </>
        )}
      />
    </Paper>
  );
};

export default MagazineOrderList;
