import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "@mui/icons-material";
import SInput from "common/material/panel/SInput";
import SSelect from "common/material/panel/SSelect";
import SButton from "common/material/panel/SButton";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router-dom";
import DropImg from "assets/drop-img-icon.png";
import { isNullEmptyUndefinedOrZero } from "utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Toaster from "common/Toaster";
import {
  getMagazineCategoryList,
  saveMagazine,
  updateMagazine,
} from "appRedux/actions/magazineAction";
import { toast } from "react-toastify";
import apiCall from "appRedux/apiCall";
import { API_MAGAZINE } from "appRedux/apiType";
import Loading from "common/loader";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // bgcolor: "#fff",
  // borderRadius: "10px",
  // boxShadow: 24,
  marginTop: "65px",
  width: "100%",
  // overflow: "auto",
  px: 4,
  py: 2,
};

const AddMagazine = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const isEdit = !isNullEmptyUndefinedOrZero(id);

  const { sellerProfile } = useSelector(
    (state) => ({
      sellerProfile: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const { loading, errors, res } = useSelector(
    (state) => ({
      loading: state.getMaterial.loading,
      errors: state.getMaterial.error,
      res: state.getMaterial.res,
    }),
    shallowEqual
  );

  const [addVariantOpen, setAddVariantOpen] = useState(false);
  const [error, setError] = useState.apply({});
  const [payload, setPayload] = useState({
    owner: sellerProfile?.id,
    title: "",
    category: "",
    price: "",
    cover: "",
    pdf: "",
  });

  const [categories, setCategories] = useState({});
  const [editDetails, setEditDetails] = useState({});

  const fileTypes = ["JPG", "PNG", "GIF"];

  const { createLoading, createErrors, createRes } = useSelector(
    (state) => ({
      createLoading: state.createMagazine.loading,
      createErrors: state.createMagazine.error,
      createRes: state.createMagazine.res,
    }),
    shallowEqual
  );

  const { updateLoading, updateErrors, updateRes } = useSelector(
    (state) => ({
      updateLoading: state.updateMagazine.loading,
      updateErrors: state.updateMagazine.error,
      updateRes: state.updateMagazine.res,
    }),
    shallowEqual
  );

  const categories_store = useSelector(
    (state) => ({
      loading: state.getMagazineCategories.loading,
      errors: state.getMagazineCategories.error,
      res: state.getMagazineCategories.res,
    }),
    shallowEqual
  );

  const handleSaveMagazine = () => {
    if (isEdit) {
      handleEditMagazine();
      return;
    }
    if (isNullEmptyUndefinedOrZero(payload.title)) {
      Toaster({ type: "error", message: "Title is required" });
      return;
    }
    if (isNullEmptyUndefinedOrZero(payload.category)) {
      Toaster({ type: "error", message: "Category is required" });
      return;
    }
    if (isNullEmptyUndefinedOrZero(payload.price)) {
      payload.price = 0;
    }
    if (isNullEmptyUndefinedOrZero(payload?.cover?.name)) {
      Toaster({ type: "error", message: "Cover is required" });
      return;
    }
    if (isNullEmptyUndefinedOrZero(payload?.pdf?.name)) {
      Toaster({ type: "error", message: "PDF is required" });
      return;
    }

    dispatch(saveMagazine(payload));
  };

  const [editPayload, setEditPayLoad] = useState({});

  const getCategoryDetail = (id) => {
    for (var i in categories) {
      if (id === categories[i].key) {
        return { id: categories[i].key, title: categories[i].display };
      }
    }
  };

  const getMagazineDetail = () => {
    apiCall({
      apiType: "GET",
      type: `${API_MAGAZINE}${id}`,
    }).then((res) => {
      const magazine = res.data;
      setPayload({
        title: magazine.title || "",
        category: magazine.category_detail?.id || "",
        price: magazine.price || "",
        cover: magazine.cover || "",
        pdf: magazine.pdf || "",
      });
      setEditDetails({
        title: magazine.title || "",
        category: magazine.category_detail?.id || "",
        price: magazine.price || "",
        cover: magazine.cover || "",
        pdf: magazine.pdf || "",
      });
    });
  };

  const handleEditMagazine = () => {
    // for (var key in editPayload) {
    //   props.row[key] = editPayload[key];
    //   if (key === "category") {
    //     props.row["category_detail"] = getCategoryDetail(editPayload[key]);
    //   }
    // }
    // if (!isNullEmptyUndefinedOrZero(editPayload))
    //   // dispatch(updateMagazine({ ...editPayload, id: props.row.id }));

    //   navigate("/magazines");

    const newPayload = {};

    if (payload.title !== editDetails.title) newPayload.title = payload.title;
    if (payload.category !== editDetails.category)
      newPayload.category = payload.category;
    if (payload.price !== editDetails.price) newPayload.price = payload.price;
    if (payload.cover !== editDetails.cover) newPayload.cover = payload.cover;
    if (payload.pdf !== editDetails.pdf) newPayload.pdf = payload.pdf;

    newPayload.id = id;

    dispatch(updateMagazine(newPayload));
  };

  useEffect(() => {
    if (createRes) {
      // props?.addMagazine(createRes);
      navigate("/magazines");
      Toaster({ type: "success", message: "Magazine Added" });
    }
  }, [createRes]);

  useEffect(() => {
    if (!isNullEmptyUndefinedOrZero(categories_store?.res?.results))
      setCategories(
        categories_store.res.results.map((x, index) => ({
          key: x.id,
          display: x.name,
        }))
      );

    if (!isNullEmptyUndefinedOrZero(props.row)) setPayload(props.row);
  }, [categories_store?.res?.results]);
  useEffect(() => {
    dispatch(getMagazineCategoryList());
    if (isEdit) getMagazineDetail();
    // if (isNullEmptyUndefinedOrZero(sellerProfileres))
    // dispatch(getSellerProfile());
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontFamily: "Medium65", fontSize: "1.3rem" }}>
            {isEdit ? "Edit Magazine" : "Add New Magazine"}
          </Typography>
          {/* <IconButton
            sx={{ background: "#F5F5F5" }}
            onClick={props.handleClose}
          >
            <Close fontSize="small" color="#000" />
          </IconButton> */}
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Grid sx={{ mb: 2 }}>
                <SInput
                  tooltip={true}
                  label="Magazine Title"
                  value={payload.title}
                  onChange={(e) => {
                    if (e.target.value.length <= 75) {
                      setPayload({ ...payload, title: e.target.value });
                      if (!isNullEmptyUndefinedOrZero(props.row)) {
                        setEditPayLoad({
                          ...editPayload,
                          title: e.target.value,
                        });
                      }
                    }
                  }}
                  tip="Magazine Name"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle3"
                          sx={{ fontSize: "15px" }}
                        >{`${payload.title.length}/75`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                  error={error && error.name}
                />
              </Grid>

              <Grid sx={{ mb: 2 }}>
                <SInput
                  tooltip={true}
                  label="Magazine Price"
                  value={payload.price}
                  onChange={(e) => {
                    if (e.target.value.length <= 75) {
                      setPayload({ ...payload, price: e.target.value });
                      if (!isNullEmptyUndefinedOrZero(props.row)) {
                        setEditPayLoad({
                          ...editPayload,
                          price: e.target.value,
                        });
                      }
                    }
                  }}
                  tip="Magazine Price"
                  error={error && error.name}
                />
              </Grid>

              <Grid sx={{ mb: 2 }}>
                <SSelect
                  tooltip={true}
                  label="Category"
                  tip="Select Category"
                  placeholder="Select Category"
                  valueKey="key"
                  value={payload.category}
                  displayKey="display"
                  options={
                    !isNullEmptyUndefinedOrZero(categories) && categories
                  }
                  onChange={(e) => {
                    setPayload({ ...payload, category: e.target.value });
                    if (!isNullEmptyUndefinedOrZero(props.row)) {
                      setEditPayLoad({
                        ...editPayload,
                        category: e.target.value,
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={8}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid sx={{ mb: 2 }}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    component="legend"
                    sx={{
                      color: "#000",
                      fontFamily: "Roman55",
                      fontSize: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    Upload Cover Photo Of Magazine
                    <Tooltip title={props.tip} placement="right">
                      <Box
                        component="span"
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "21px",
                          width: "21px",
                          padding: "2px",
                          ml: 1,
                          background: "#707070",
                          borderRadius: "50%",
                          color: "#fff",
                        }}
                      >
                        ?
                      </Box>
                    </Tooltip>
                  </FormLabel>
                  {payload.cover ? (
                    <Box
                      sx={{
                        position: "relative",
                        border: "2px solid #F3F3F3",
                        borderRadius: "10px",
                        width: "170px",
                        height: "170px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ position: "relative", width: "100%" }}
                        src={
                          typeof payload.cover === "string"
                            ? payload.cover
                            : URL.createObjectURL(payload.cover)
                        }
                        alt="not found"
                      />
                      <IconButton
                        sx={{
                          width: "25px",
                          height: "25px",
                          background: "rgba(0,0,0,.2)",
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                        onClick={() => setPayload({ ...payload, cover: "" })}
                      >
                        <Close fontSize="small" sx={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <FileUploader
                      name="file"
                      types={fileTypes}
                      onDrop={(file) => {
                        setPayload({ ...payload, cover: file });
                        if (!isNullEmptyUndefinedOrZero(props.row)) {
                          setEditPayLoad({ ...editPayload, cover: file });
                        }
                      }}
                      onSelect={(file) => {
                        setPayload({ ...payload, cover: file });
                        if (!isNullEmptyUndefinedOrZero(props.row)) {
                          setEditPayLoad({ ...editPayload, cover: file });
                        }
                      }}
                      children={
                        <Box
                          sx={{
                            border: "2px dashed #F3F3F3",
                            position: "relative",
                            borderRadius: "10px",
                            width: "170px",
                            height: "170px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={DropImg} alt="not found" />
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Book45",
                              fontSize: "12px",
                            }}
                          >
                            Drag your images here,
                            <br />
                            or{" "}
                            <Box
                              component="span"
                              sx={{
                                fontFamily: "Heavy85",
                                color: "#006156",
                                textDecoration: "underline",
                              }}
                            >
                              Browse
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                  <FormHelperText
                    sx={{ color: "#F0142F", fontFamily: "Roman55" }}
                  >
                    {props.error && props.error}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid sx={{ mb: 2 }}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel
                    component="legend"
                    sx={{
                      color: "#000",
                      fontFamily: "Roman55",
                      fontSize: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    Upload Magazine PDF
                    <Tooltip title={props.tip} placement="right">
                      <Box
                        component="span"
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "21px",
                          width: "21px",
                          padding: "2px",
                          ml: 1,
                          background: "#707070",
                          borderRadius: "50%",
                          color: "#fff",
                        }}
                      >
                        ?
                      </Box>
                    </Tooltip>
                  </FormLabel>
                  {payload.pdf ? (
                    <Box
                      sx={{
                        position: "relative",
                        border: "2px solid #F3F3F3",
                        borderRadius: "10px",
                        width: "170px",
                        height: "170px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Typography>{payload.pdf.name}</Typography> */}
                      <iframe
                        style={{ position: "relative", width: "100%" }}
                        src={
                          typeof payload.pdf === "string"
                            ? payload.pdf
                            : URL.createObjectURL(payload.pdf)
                        }
                        alt="not found"
                      />
                      {/* <img
                        style={{ position: "relative", width: "100%" }}
                        src={
                          typeof payload.pdf === "string"
                            ? payload.pdf
                            : payload.pdf.name
                        }
                        alt="not found"
                      /> */}
                      <IconButton
                        sx={{
                          width: "25px",
                          height: "25px",
                          background: "rgba(0,0,0,.2)",
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                        onClick={() => setPayload({ ...payload, pdf: "" })}
                      >
                        <Close fontSize="small" sx={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <FileUploader
                      name="file"
                      types={["pdf", "PDF"]}
                      onDrop={(file) => {
                        setPayload({ ...payload, pdf: file });
                        if (!isNullEmptyUndefinedOrZero(props.row)) {
                          setEditPayLoad({ ...editPayload, pdf: file });
                        }
                      }}
                      onSelect={(file) => {
                        setPayload({ ...payload, pdf: file });
                        if (!isNullEmptyUndefinedOrZero(props.row)) {
                          setEditPayLoad({ ...editPayload, pdf: file });
                        }
                      }}
                      children={
                        <Box
                          sx={{
                            border: "2px dashed #F3F3F3",
                            position: "relative",
                            borderRadius: "10px",
                            width: "170px",
                            height: "170px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={DropImg} alt="not found" />
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontFamily: "Book45",
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                          >
                            Drag your Pdf here,
                            <br />
                            or{" "}
                            <Box
                              component="span"
                              sx={{
                                fontFamily: "Heavy85",
                                color: "#006156",
                                textDecoration: "underline",
                              }}
                            >
                              Browse
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                  <FormHelperText
                    sx={{ color: "#F0142F", fontFamily: "Roman55" }}
                  >
                    {props.error && props.error}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3, borderBottomWidth: 2, opacity: ".5" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SButton
              mySx={{ mr: 2, width: "100px" }}
              onClick={() => navigate("/magazines")}
            >
              Cancel
            </SButton>
            <SButton
              mySx={{ borderRadius: "20px", width: "140px" }}
              variant="contained"
              disabled={createLoading || updateLoading}
              endIcon={
                createLoading || updateLoading ? <Loading size={20} /> : null
              }
              onClick={
                isNullEmptyUndefinedOrZero(props.row)
                  ? handleSaveMagazine
                  : handleEditMagazine
              }
            >
              Save
            </SButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddMagazine;
