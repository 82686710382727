import AddMagazine from "component/magazine/AddMagazine";
import MagazineOrders from "component/magazineorders";
import React from "react";

const Dashboard = React.lazy(() => import("../component/dashboard"));
const Product = React.lazy(() => import("../component/product"));
const Magazine = React.lazy(() => import("../component/magazine"));
const Store = React.lazy(() => import("../component/store"));
const Order = React.lazy(() => import("../component/order"));
const OrderDetails = React.lazy(() => import("../component/order/OrderDetail"));
const Rating = React.lazy(() => import("../component/rating"));
const Faqs = React.lazy(() => import("../component/faqs"));
const Account = React.lazy(() => import("../component/account"));
const Earning = React.lazy(() => import("../component/earnings"));
const RecentEarning = React.lazy(() =>
  import("component/earnings/recentearning/RecentEarning")
);
const PastEarning = React.lazy(() =>
  import("component/earnings/pastearning/PastEarning")
);
const AddProduct = React.lazy(() => import("component/product/AddProduct"));
const AddVariant = React.lazy(() => import("component/product/AddVariant"));
const EditProduct = React.lazy(() => import("component/product/EditProduct"));
const ProductDetails = React.lazy(() =>
  import("component/product/ProductDetails")
);
const EditVariant = React.lazy(() => import("component/product/EditVariant"));

const ScreenRoutes = [
  { path: "dashboard", name: "Dashboard", element: <Dashboard /> },
  { path: "", name: "Dashboard", element: <Dashboard /> },
  { path: "products", name: "Products", element: <Product /> },
  {
    path: "products/details/:id",
    name: "Products-Details",
    element: <ProductDetails />,
  },
  {
    path: "products/add",
    name: "Add-Product",
    element: <AddProduct />,
  },
  {
    path: "products/edit/:id",
    name: "Edit-Product",
    element: <EditProduct />,
  },
  {
    path: "products/:id/variant/add",
    name: "Add-Variant",
    element: <AddVariant />,
  },
  {
    path: "products/:productId/variant/edit/:id",
    name: "Edit-Variant",
    element: <EditVariant />,
  },
  { path: "magazines", name: "Magazines", element: <Magazine /> },
  {
    path: "magazines/orders",
    name: "Magazines-Orders",
    element: <MagazineOrders />,
  },
  { path: "magazines/add", name: "Add-Magazine", element: <AddMagazine /> },
  {
    path: "magazines/edit/:id",
    name: "Edit-Magazine",
    element: <AddMagazine />,
  },

  { path: "orders", name: "Order", element: <Order /> },
  {
    path: "orders/details/:id",
    name: "Order-Detail",
    element: <OrderDetails />,
  },
  { path: "rating", name: "Rating", element: <Rating /> },
  { path: "Faqs", name: "Faqs", element: <Faqs /> },
  { path: "account", name: "My Account", element: <Account /> },
  { path: "earning", name: "My Earning", element: <Earning /> },
  { path: "recentearning", name: "My Earning", element: <RecentEarning /> },
  { path: "Pastearning", name: "My Earning", element: <PastEarning /> },
];

export default ScreenRoutes;
