import { Box, Typography } from "@mui/material";
import { getMagazineOrderList } from "appRedux/actions/magazineAction";
import SButton from "common/material/panel/SButton";
import TopBar from "layout/TopBar";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MagazineOrderList from "./MagazineOrderList";
import NoProduct from "assets/no-product.png";
import { isNullEmptyUndefinedOrZero } from "utils";
import { getSellerProfile } from "appRedux/actions/storeAction";

const MagazineOrders = () => {
  const dispatch = useDispatch();

  const { loading, errors, res } = useSelector(
    (state) => ({
      loading: state.getMagazineOrder.loading,
      errors: state.getMagazineOrder.error,
      res: state.getMagazineOrder.res,
    }),
    shallowEqual
  );

  const { sellerProfileloading, sellerProfileerrors, sellerProfileres } =
    useSelector(
      (state) => ({
        sellerProfileloading: state.sellerProfile.loading,
        sellerProfileerrors: state.sellerProfile.error,
        sellerProfileres: state.sellerProfile.res,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch(getMagazineOrderList());
    if (isNullEmptyUndefinedOrZero(sellerProfileres))
      dispatch(getSellerProfile());
  }, []);

  return (
    <>
      <TopBar title="Magazine Orders" />
      <Box sx={{ marginTop: "65px", width: "100%", overflow: "auto" }}>
        {!loading && res.count === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 65px)",
            }}
          >
            <img src={NoProduct} alt="Noproduct" />
            <Typography sx={{ fontFamily: "Heavy85", fontSize: "3rem", mb: 1 }}>
              Oops!
            </Typography>
            <Typography sx={{ fontFamily: "Book45", fontSize: "1rem", mb: 2 }}>
              It seems like you haven't added any Magazine yet now.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ paddingLeft: "25px", paddingRight: "25px" }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
            >
              <Typography
                sx={{
                  fontFamily: "Medium65",
                  fontSize: "1.75rem",
                }}
              >
                Total Magazine Orders
                <Box
                  component="span"
                  sx={{
                    ml: 2,
                    px: 3,
                    py: 0.8,
                    width: "100px",
                    borderRadius: "25px",
                    background: "#F3F3F3",
                    fontFamily: "Black95",
                    fontSize: "1.1rem",
                  }}
                >
                  {res.count}
                </Box>
              </Typography>
            </Box>
            {res && (
              <MagazineOrderList
                rows={res.results}
                // deleteRow={handleDeleteRow}
                // editMagazine={handleEditRow}
                // changeActiveStatus={handleProductActiveStatus}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default MagazineOrders;
